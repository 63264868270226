<template>
  <div>
    {{ $t('...loading') }}
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import navMenuItems, { systemSettingsTabs } from '@/navigation/vertical'

export default {
  computed: {
    ...mapState('user', ['categorizedDashboardLists', 'user']),
    ...mapGetters('user', ['customEntities']),
  },
  mounted() {
    this.setLandingPage()
  },

  methods: {
    async setLandingPage() {
      try {
        if (this.categorizedDashboardLists.length) {
          const [dashboard] = this.categorizedDashboardLists
          this.$router.replace(dashboard.href)
        } else {
          const result = navMenuItems.filter(nav => this.matchPermission(nav.resource) && nav.standard)
          if (result.length) {
            this.$router.replace({ name: result[0].route })
          } else if (this.customEntities.length) {
            this.$router.replace(`/custom-entity/${this.customEntities[0].key}`)
          } else {
            // with permissions property
            const navWithPermissions = navMenuItems
              .filter(_nav => _nav.permissions && !_nav.header)
              .filter(nav => nav.permissions.some(permission => this.matchPermission(permission.resource)))

            if (navWithPermissions.length) {
              this.$router.replace({ name: navWithPermissions[0].route })
            } else {
              // settings permissions

              const settingsTabs = systemSettingsTabs.filter(tab => tab.resource)

              const hasSettingsPermission = settingsTabs?.some(tab => this.matchPermission(tab.resource))

              if (hasSettingsPermission) {
                return this.$router.push('/system-settings')
              }

              //
              const nonStandard = navMenuItems.filter(nav => this.matchPermission(nav.resource) && !nav.standard)
              this.$router.replace({ name: nonStandard[0].route })
            }
          }
        }
      } catch (error) {
        this.$router.replace('/error-404')
      }
    },

    matchPermission(toMatchName) {
      return this.user.permissions.permissions.some(permission => permission.name === toMatchName)
    },
  },
}
</script>

<style></style>
